import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	body: {
		textAlign: "center",
		...styling.mixins.padding(20, 0, 0),
		[styling.sizes.breakpoints.medium]: {
			textAlign: "left",
		},
	},
	groupWrapper: (logoVisible) => ({
		display: "flex",
		position: "relative",
		textAlign: "center",
		flexWrap: "wrap",
		justifyContent: "center",
		...styling.mixins.padding(0, 0, 20),
		":after": {
			content: '""',
			position: "absolute",
			bottom: 0,
			left: 13,
			right: 13,
			...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
				1,
				"dashed",
				styling.colors.linkDefault,
			]),
		},
		":last-of-type:after": {
			content: "unset",
		},
		[styling.sizes.breakpoints.medium]: {
			justifyContent: "start",
			minHeight: 200,
			...styling.mixins.padding(0, 0, 0, logoVisible ? 200 : 0),
			":after": {
				left: 0,
				right: 0,
			},
		},
	}),
	groupImageWrapper: {
		width: "100%",
		[styling.sizes.breakpoints.medium]: {
			width: 180,
			position: "absolute",
			top: 0,
			left: 0,
		},
	},
	groupImage: {
		width: 180,
		height: 180,
		...styling.mixins.margin(0, "auto"),
	},
	resortLink: {
		display: "block",
		width: "33.33%",
		minWidth: 120,
		".gatsby-image-wrapper": {
			width: 120,
			height: 120,
			...styling.mixins.margin(0, "auto"),
		},
		[styling.sizes.breakpoints.small]: {
			width: "20%",
		},
		[styling.sizes.breakpoints.medium]: {
			minWidth: 180,
			width: "10%",
			".gatsby-image-wrapper": {
				height: 180,
				width: 180,
			},
		},
	},
	resortImageWrapper: {
		display: "block",
		width: "33.33%",
		minWidth: 120,
		".gatsby-image-wrapper": {
			width: 120,
			height: 120,
			...styling.mixins.margin(0, "auto"),
		},
		[styling.sizes.breakpoints.small]: {
			width: "20%",
		},
		[styling.sizes.breakpoints.medium]: {
			minWidth: 180,
			width: "10%",
			".gatsby-image-wrapper": {
				height: 180,
				width: 180,
			},
		},
	},
};
