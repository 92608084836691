import styling from "Services/styling";

export default {
	icon: {
		width: "40px !important",
		...styling.mixins.margin(0, "auto", 30),
	},
	header: {
		fontSize: "1.6rem",
		lineHeight: "1.9rem",
		fontWeight: 700,
		textTransform: "uppercase",
		textAlign: "center",
		...styling.mixins.margin(0, 0, 75),
	},
	wrapper: {
		maxWidth: 1000,
		width: "100% !important",
		...styling.mixins.margin(0, "auto"),
		[styling.sizes.breakpoints.small]: {
			...styling.mixins.padding(0, "15%"),
		},
	},
};
