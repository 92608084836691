import { graphql } from "gatsby";
import React from "react";

import HomepageContent from "Components/HomepageContent";
import SEO from "Components/SEO";
import Wrapper from "Components/Wrapper";

const IndexPage = ({
	data: { content, header, footer, images, legal, loginResorts, products },
	pageContext: { langKey },
}) => (
	<Wrapper
		lang={langKey}
		footer={footer}
		header={header}
		legal={legal.edges}
		loginResorts={loginResorts.edges}
		pageType="home"
		products={products.edges}>
		<SEO
			description={
				(content.description && content.description.description) || ""
			}
			lang={langKey}
			title={content.title}
		/>
		<HomepageContent {...content} images={images.edges} body={footer.body} />
	</Wrapper>
);

export default IndexPage;

export const query = graphql`
	query homePageQuery($langKey: String) {
		content: contentfulPageHome(node_locale: { eq: $langKey }) {
			...HomePageQuery
		}
		images: allFile(filter: { relativeDirectory: { eq: "home" } }) {
			edges {
				node {
					name
					childImageSharp {
						original {
							height
							src
							width
						}
					}
				}
			}
		}
		header: contentfulHeader(node_locale: { eq: $langKey }) {
			...HeaderQuery
		}
		footer: contentfulFooter(node_locale: { eq: $langKey }) {
			...FooterQuery
		}
		loginResorts: allContentfulResortLogin(
			sort: { fields: title }
			filter: { node_locale: { eq: $langKey } }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		products: allContentfulProduct(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: orderHint }
		) {
			edges {
				node {
					...ProductsMenuQuery
				}
			}
		}
		legal: allContentfulPagePlain(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: slug }
		) {
			edges {
				node {
					...PlainPagesMenuQuery
				}
			}
		}
	}
`;
