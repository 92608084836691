import { OutboundLink } from "gatsby-plugin-google-analytics";
import { string } from "prop-types";
import React from "react";

import { ResortGroupsPropTypes } from "~/types";
import Image from "Components/Image";
import Styles from "./styles.css";

const ResortGroups = ({ body, groups }) => (
	<section>
		{groups.length && <h4 css={Styles.body}>{body}</h4>}
		{groups.map((group) => (
			<section key={group.id} css={Styles.groupWrapper(group.isLogoVisible)}>
				{group.isLogoVisible && (
					<div css={Styles.groupImageWrapper}>
						<Image
							fluid={group.logo && group.logo.fluid}
							style={Styles.groupImage}
							title={group.title}
						/>
					</div>
				)}
				{group.resorts.map((resort) => {
					if (resort.website) {
						return (
							<OutboundLink
								key={resort.id}
								href={resort.website}
								target="_blank"
								css={Styles.resortLink}>
								<Image
									fluid={resort.logo && resort.logo.fluid}
									style={Styles.resortImage}
									title={resort.title}
								/>
							</OutboundLink>
						);
					}
					return (
						<div css={Styles.resortImageWrapper}>
							<Image
								fluid={resort.logo && resort.logo.fluid}
								style={Styles.resortImage}
								title={resort.title}
							/>
						</div>
					);
				})}
			</section>
		))}
	</section>
);

ResortGroups.propTypes = {
	body: string,
	groups: ResortGroupsPropTypes,
};

export default ResortGroups;
