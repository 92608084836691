import { graphql } from "gatsby";
import React from "react";
import { connect } from "react-redux";

import { CallToActionPropTypes } from "~/types";
import Button from "Components/Button";
import { Messages } from "Components/Icons";
import Styles from "./styles.css";

const RequestDemo = ({ buttonText, openModal, title }) => (
	<div>
		<div css={Styles.buttonContainer}>
			<Button onClick={openModal} onKeyPress={openModal}>
				{buttonText}
			</Button>
		</div>
	</div>
);

const mapDispatchToProps = (dispatch, ownProps) => ({
	openModal: () =>
		dispatch({
			type: "MODAL_KEY_SET",
			payload: {
				modalKey: ownProps.slug,
				modalProps: {
					title: ownProps.title,
					slug: ownProps.slug,
					buttonText: ownProps.buttonText,
					thankYou: ownProps.thankYou,
				},
			},
		}),
});

export default connect(
	null,
	mapDispatchToProps
)(RequestDemo);
