import React from "react";

import styling from "Services/styling";

export default () => (
	<div
		css={{
			maxWidth: 1000,
			width: "100% !important",
			...styling.mixins.margin(0, "auto"),
		}}>
		<svg width="100%" viewBox="0 0 353 306">
			<g>
				<g transform="translate(-21.000000, -986.000000)" fill="#1B232C">
					<g transform="translate(20.000000, 986.000000)">
						<path d="M187.203597,0 C207.814609,0 227.809862,4.03860895 246.634061,12.0032512 C264.815624,19.6960317 281.143524,30.7082813 295.164925,44.7345334 C309.186326,58.7607856 320.195103,75.0946704 327.884887,93.2821885 C328.867148,95.6056152 329.789613,97.9471885 330.651946,100.305564 L330.633707,100.256 L331.334547,100.068447 C331.6451,99.9852057 331.970194,99.9782994 332.282369,100.047028 L332.467904,100.097336 C333.518857,100.437297 334.09523,101.564854 333.755269,102.615806 L333.755269,102.615806 L332.340341,106.989914 C332.253522,107.258305 332.110911,107.505296 331.921871,107.714665 C331.181642,108.534503 329.916958,108.599039 329.09712,107.858809 L329.09712,107.858809 L325.685458,104.77843 C325.399217,104.519984 325.193802,104.184291 325.093955,103.811787 C324.807979,102.74488 325.441049,101.648151 326.507957,101.362175 L326.507957,101.362175 L327.700018,101.04335 C326.886882,98.8335011 326.021405,96.6384889 325.10036,94.4603682 C317.562752,76.633092 306.771994,60.6228203 293.027064,46.8731345 C279.282133,33.1237848 263.277063,22.3286214 245.456288,14.7887421 C227.006988,6.98271387 207.407798,3.02442008 187.203597,3.02442008 C166.999395,3.02442008 147.400205,6.98271387 128.950905,14.7887421 C111.129794,22.3286214 95.1247237,33.1237848 81.3801293,46.8731345 C67.6351989,60.6228203 56.8444409,76.633092 49.3068334,94.4603682 C48.3343148,96.7599356 47.4239432,99.0779855 46.5716877,101.412502 C46.3476221,102.026459 45.7557126,102.432403 45.1033357,102.398799 C45.0929218,102.398127 45.082508,102.397455 45.0717582,102.397119 C44.0552327,102.345703 43.3793406,101.334539 43.7287083,100.37815 C44.5987681,97.9952431 45.5296314,95.6294746 46.5223059,93.2821885 C54.2120899,75.0946704 65.2208667,58.7607856 79.2422679,44.7345334 C93.263669,30.7082813 109.591569,19.6960317 127.772797,12.0032512 C146.596995,4.03860895 166.592584,0 187.203597,0 Z" />
						<path d="M42.0781513,197.43954 C42.8183816,196.619703 44.0830661,196.555169 44.9029032,197.295399 L44.9029032,197.295399 L48.3145768,200.375797 C48.6008155,200.634242 48.8062294,200.969932 48.9060765,201.342432 C49.1920556,202.409339 48.5589887,203.50607 47.4920821,203.792049 L47.4920821,203.792049 L46.598,204.031779 L47.0900472,205.359147 C47.7905777,207.221426 48.5288185,209.072909 49.3068334,210.912563 C56.8444409,228.739839 67.6351989,244.750111 81.3801293,258.499796 C95.1247237,272.249146 111.129794,283.044309 128.950905,290.584189 C147.400205,298.390217 166.999395,302.348511 187.203597,302.348511 C207.407798,302.348511 227.006988,298.390217 245.456288,290.584189 C263.277063,283.044309 279.282133,272.249146 293.027064,258.499796 C306.771994,244.750111 317.562752,228.739839 325.10036,210.912563 C326.063808,208.634166 326.966453,206.337287 327.81199,204.024278 C328.03404,203.416369 328.614192,203.01513 329.260858,203.027227 C330.30325,203.046718 331.010048,204.088127 330.651946,205.067367 C329.789613,207.425742 328.867148,209.767316 327.884887,212.090742 C320.195103,230.27826 309.186326,246.612145 295.164925,260.638397 C281.143524,274.66465 264.815624,285.676899 246.634061,293.36968 C227.809862,301.334322 207.814609,305.372931 187.203597,305.372931 C166.592584,305.372931 146.596995,301.334322 127.772797,293.36968 C109.591569,285.676899 93.263669,274.66465 79.2422679,260.638397 C65.2208667,246.612145 54.2120899,230.27826 46.5223059,212.090742 C45.5296314,209.743456 44.5987681,207.377688 43.7287083,204.994781 C43.7068879,204.935048 43.6890668,204.875101 43.6750784,204.815188 L42.6654604,205.085801 C42.2927937,205.185693 41.8991884,205.17566 41.5320947,205.056913 C40.4811427,204.716951 39.9047711,203.589393 40.2447326,202.538441 L40.2447326,202.538441 L41.6596808,198.164291 C41.7464999,197.895899 41.8891118,197.648909 42.0781513,197.43954 Z" />
						<text
							fontFamily={styling.fontFamily}
							fontSize="16"
							fontWeight="bold"
							letterSpacing="-0.24">
							<tspan x="23.328" y="144.5">
								Simple
							</tspan>
							<tspan x="0" y="160.5">
								Management
							</tspan>
							<tspan x="28.512" y="176.5">
								Tools
							</tspan>
						</text>
						<text
							fontFamily={styling.fontFamily}
							fontSize="16"
							fontWeight="bold"
							letterSpacing="-0.24">
							<tspan x="310" y="153">
								Smart
							</tspan>
							<tspan x="314.776" y="169">
								Data
							</tspan>
						</text>
					</g>
				</g>
			</g>
		</svg>
	</div>
);
