import styling from "Services/styling";

export default {
	wrapper: {
		minHeight: 0,
		alignItems: "start",
		"& > *": {
			width: "100%",
			maxWidth: 1115,
		},
	},
	headerStrap: {
		fontSize: "2rem",
		lineHeight: "2.4rem",
		[styling.sizes.breakpoints.medium]: {
			fontSize: "2.4rem",
			lineHeight: "3rem",
		},
	},
	videoContainer: {
		padding: "56.25% 0 0 0",
		position: "relative",
	},
	videoIFrame: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	},
	buttonContainer: {
		textAlign: "center",
		maxWidth: 220,
		...styling.mixins.margin(0, "auto"),
		...styling.mixins.padding(30, 0, 0),
	},
};
