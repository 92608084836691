import React from "react";
import { string } from "prop-types";

import { Infinity } from "Components/Icons";
import styling from "Services/styling";
import Styles from "./styles.css";

const FlaikFlow = ({ text }) => (
	<>
		<div css={Styles.icon}>
			<Infinity />
		</div>
		<div css={Styles.header}>{text}</div>

		<div css={Styles.wrapper}>
			<svg viewBox="0 0 600 613">
				<text
					fontFamily={styling.fontFamily}
					fontSize="30"
					fontWeight="bold"
					fill={styling.colors.textTertiary}>
					<tspan x="158.175" y="577.999939">
						Simple management
					</tspan>
					<tspan x="149.25" y="609.999939">
						software + smart data
					</tspan>
				</text>
				<text
					fontFamily={styling.fontFamily}
					fontSize="30"
					fontWeight="bold"
					fill={styling.colors.textTertiary}>
					<tspan x="188.365" y="441.999939">
						Better decisions
					</tspan>
					<tspan x="218.41" y="473.999939">
						&amp; behaviors
					</tspan>
				</text>
				<text
					fontFamily={styling.fontFamily}
					fontSize="30"
					fontWeight="bold"
					fill={styling.colors.textTertiary}>
					<tspan x="189.415" y="305.999939">
						Better lessons &amp;
					</tspan>
					<tspan x="181.285" y="337.999939">
						guest experience
					</tspan>
				</text>
				<text
					fontFamily={styling.fontFamily}
					fontSize="30"
					fontWeight="bold"
					fill={styling.colors.textTertiary}>
					<tspan x="214.895" y="169.999939">
						Better guest
					</tspan>
					<tspan x="171.155" y="201.999939">
						retention &amp; loyalty
					</tspan>
				</text>
				<text
					fontFamily={styling.fontFamily}
					fontSize="30"
					fontWeight="bold"
					fill={styling.colors.textTertiary}>
					<tspan x="261.225" y="33.9999392">
						Better
					</tspan>
					<tspan x="235.65" y="65.9999392">
						ski school{" "}
					</tspan>
				</text>
				<path
					fill={styling.colors.primary}
					d="M492.5355,429.829 C493.6401,429.829 494.5355,430.724 494.5355,431.829 L494.535658,441.001924 C534.605877,441.289885 567,473.861919 567,514 C567,554.31681 534.316797,587 494,587 C491.238576,587 489,584.761424 489,582 C489,579.238576 491.238576,577 494,577 C528.793951,577 557,548.793962 557,514 C557,479.206064 528.793936,451 494,451 L494.534504,451.004 L494.5355,461.242 C494.5355,461.628 494.4239,462.005 494.2142,462.329 C493.6137,463.256 492.3754,463.521 491.4483,462.921 L468.7425,448.214 C468.5059,448.061 468.3043,447.859 468.1511,447.623 C467.5506,446.696 467.8154,445.457 468.7425,444.857 L491.4483,430.15 C491.7721,429.941 492.1497,429.829 492.5355,429.829 Z"
				/>
				<path
					fill={styling.colors.primary}
					d="M492.5355,156.829 C493.6401,156.829 494.5355,157.724 494.5355,158.829 L494.535658,169.001924 C534.605877,169.289885 567,201.861919 567,242 C567,282.31681 534.316797,315 494,315 C491.238576,315 489,312.761424 489,310 C489,307.238576 491.238576,305 494,305 C528.793951,305 557,276.793962 557,242 C557,207.206064 528.793936,179 494,179 L494.534504,179.004 L494.5355,188.242 C494.5355,188.628 494.4239,189.005 494.2142,189.329 C493.6137,190.256 492.3754,190.521 491.4483,189.921 L468.7425,175.214 C468.5059,175.061 468.3043,174.859 468.1511,174.623 C467.5506,173.696 467.8154,172.457 468.7425,171.857 L491.4483,157.15 C491.7721,156.941 492.1497,156.829 492.5355,156.829 Z"
				/>
				<path
					fill={styling.colors.primary}
					d="M109.5355,292.829 C109.9214,292.829 110.299,292.941 110.6228,293.15 L133.3286,307.857 C134.2557,308.457 134.5205,309.696 133.92,310.623 C133.7667,310.859 133.5652,311.061 133.3286,311.214 L110.6228,325.921 C109.6957,326.521 108.4574,326.256 107.8569,325.329 C107.6471,325.005 107.5355,324.628 107.5355,324.242 L107.534584,315.001683 C72.9548823,315.251874 45,343.361334 45,378 C45,412.793962 73.2060495,441 108,441 C110.761424,441 113,443.238576 113,446 C113,448.761424 110.761424,451 108,451 C67.6832029,451 35,418.31681 35,378 C35,337.683216 67.6832163,305 108,305 L107.535,305.003 L107.5355,294.829 C107.5355,293.724 108.431,292.829 109.5355,292.829 Z"
				/>
				<path
					fill={styling.colors.primary}
					d="M109.5355,21.829 C109.9214,21.829 110.299,21.941 110.6228,22.15 L133.3286,36.857 C134.2557,37.457 134.5205,38.696 133.92,39.623 C133.7667,39.859 133.5652,40.061 133.3286,40.214 L110.6228,54.921 C109.6957,55.521 108.4574,55.256 107.8569,54.329 C107.6471,54.005 107.5355,53.628 107.5355,53.242 L107.534584,43.0016832 C72.9548823,43.2518744 45,71.3613342 45,106 C45,140.793962 73.2060495,169 108,169 C110.761424,169 113,171.238576 113,174 C113,176.761424 110.761424,179 108,179 C67.6832029,179 35,146.31681 35,106 C35,65.6832163 67.6832163,33 108,33 L107.535,33.003 L107.5355,23.829 C107.5355,22.724 108.431,21.829 109.5355,21.829 Z"
				/>
				<path
					fill={styling.colors.primary}
					d="M541.5355,21.829 C541.9214,21.829 542.299,21.941 542.6228,22.15 L565.3286,36.857 C566.2557,37.457 566.5205,38.696 565.92,39.623 C565.7667,39.859 565.5652,40.061 565.3286,40.214 L542.6228,54.921 C541.6957,55.521 540.4574,55.256 539.8569,54.329 C539.6471,54.005 539.5355,53.628 539.5355,53.242 L539.535,43.5 L467,43.5 C464.311245,43.5 462.118182,41.3776933 462.00462,38.7168896 L462,38.5 C462,35.7385763 464.238576,33.5 467,33.5 L467,33.5 L539.535,33.5 L539.5355,23.829 C539.5355,22.724 540.431,21.829 541.5355,21.829 Z"
				/>
				<path
					fill={styling.colors.primary}
					d="M79.5355,564.829 C79.9214,564.829 80.299,564.941 80.6228,565.15 L103.3286,579.857 C104.2557,580.457 104.5205,581.696 103.92,582.623 C103.7667,582.859 103.5652,583.061 103.3286,583.214 L80.6228,597.921 C79.6957,598.521 78.4574,598.256 77.8569,597.329 C77.6471,597.005 77.5355,596.628 77.5355,596.242 L77.535,586.5 L5,586.5 C2.3112453,586.5 0.118181885,584.377693 0.00461951385,581.71689 L0,581.5 C0,578.738576 2.23857625,576.5 5,576.5 L5,576.5 L77.535,576.5 L77.5355,566.829 C77.5355,565.724 78.431,564.829 79.5355,564.829 Z"
				/>
			</svg>
		</div>
	</>
);

FlaikFlow.propTypes = {
	text: string,
};

export default FlaikFlow;
